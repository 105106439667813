import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'

const ContactForm = ({ intl }) => (
  <section className="section  is-medium has-background-primary contact-form">
    <div className="container">
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        style={{
          padding: '40px',
          background: '#89A5B8',
        }}
      >
        <input type="hidden" name="bot-field" />
        <h3 className="title has-text-centered">
          <FormattedMessage id="contactUsForAConversation" />
        </h3>
        <div className="control">
          <input
            className="input"
            type="text"
            name="Navn"
            placeholder={intl.formatMessage({ id: 'namePlaceholder' })}
          />
        </div>
        <div className="control">
          <input
            className="input"
            type="email"
            placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
            name="Epost"
          />
        </div>
        <div className="control">
          <input
            className="input"
            type="phone"
            placeholder={intl.formatMessage({ id: 'phonePlaceholder' })}
            name="telefon"
          />
        </div>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={intl.formatMessage({ id: 'companyPlaceholder' })}
            name="Firma"
          />
        </div>
        <div className="control">
          <div className="select">
            <select className="input" name="Tjeneste" id="select-status">
              <option value="" disabled>
                {intl.formatMessage({ id: 'chooseService' })}
              </option>
              <option value="rekruttering">
                {intl.formatMessage({ id: 'recruitment' })}
              </option>
              <option value="bemanning">
                {intl.formatMessage({ id: 'staffing' })}
              </option>
              <option value="search">
                {intl.formatMessage({ id: 'search' })}
              </option>
              <option value="konsulent">
                {intl.formatMessage({ id: 'consultancyServices' })}
              </option>
              <option value="partner">
                {intl.formatMessage({ id: 'partnerManagement' })}
              </option>
            </select>
          </div>
        </div>
        <div className="control">
          <input className="button" type="submit" value="Send" />
        </div>
      </form>
    </div>
  </section>
)

ContactForm.propTypes = {
  currentService: PropTypes.string,
  intl: PropTypes.object,
}

export default injectIntl(ContactForm)

// </form>
