import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import Content, { HTMLContent } from '../components/Content'
import Hero from '../components/Hero'
import { withIntl } from '../i18n'

export const ServicesPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <Hero title={title} />
      <section className="section is-medium tjenester-content">
        <div className="container">
          <PageContent className="content" content={content} />
        </div>
      </section>
      <ContactForm />
    </div>
  )
}

ServicesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  seoDescription: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ServicesPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout
      seoDescription={post.frontmatter.seoDescription}
      seoTitle={post.frontmatter.seoTitle}
    >
      <ServicesPageTemplate
        contentComponent={HTMLContent}
        content={post.html}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withIntl(ServicesPage)

export const pageQuery = graphql`
  query TjenesterPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seoDescription
        seoTitle
      }
    }
  }
`
